import React, {Component} from 'react'
import './view-banner.css'

export default class ViewBanner extends Component {

  render() {
    const { title, description} = this.props
    return (
      <div className="banner-container navBarNavigation">
        <div>
          <p className="banner-title">{title}</p>
          <p className="banner-text">{description}</p>
        </div>
      </div>
    )
  }
}
